import Axios from 'axios';

import { API_URL } from '@/shared/config';

export const axios = Axios.create({
  baseURL: API_URL
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const responseCode = error.response.status;
    if (responseCode === 401) {
      window.location.href = `${API_URL + '/login'}`;
    } else if (responseCode === 403) {
      window.location.href = '/unauthorized';
    }
    return await Promise.reject(error);
  }
);
